// Area code (2 letters)
// Age identifier (2 numbers)
// Random letters (3 letters)

function plateSplit(plate, modern) {

  var plate = cleanPlate(plate);
  if ((!validatePlateUKModern(plate).valid) && (!validatePlateOther(plate).valid)) return null;

  var combinations = countCombinations(plate, modern);
  var plates = [];
  plates.push(plate);
  var index = plate.indexOf("?");
  while (index >= 0) {
    var newplates = [];
    for (pcount = 0; pcount < plates.length; pcount++) {
      if (modern) {
        var start = (index == 2 || index == 3) ? '0'.charCodeAt(0) : 'A'.charCodeAt(0);
        var end = (index == 2 || index == 3) ? '9'.charCodeAt(0) : 'Z'.charCodeAt(0);

        for (char_count = start; char_count <= end; char_count++) {
          var fixplate = plates[pcount].substring(0, index) + String.fromCharCode(char_count) + plates[pcount].substring(index + 1)
          newplates.push(fixplate)
        }
      } else {
        var start = '0'.charCodeAt(0);
        var end = '9'.charCodeAt(0);

        for (char_count = start; char_count <= end; char_count++) {
          var fixplate = plates[pcount].substring(0, index) + String.fromCharCode(char_count) + plates[pcount].substring(index + 1)
          newplates.push(fixplate)
        }

        start = 'A'.charCodeAt(0);
        end = 'Z'.charCodeAt(0);

        for (char_count = start; char_count <= end; char_count++) {
          var fixplate = plates[pcount].substring(0, index) + String.fromCharCode(char_count) + plates[pcount].substring(index + 1)
          newplates.push(fixplate)
        }
      }
    }

    plates = newplates
    index = plates[0].indexOf("?");
  }

  return plates;
}


function countCombinations(plate, modern) {
  var plate = cleanPlate(plate);
  if ((!validatePlateUKModern(plate).valid) && (!validatePlateOther(plate).valid)) return null;

  var combinations = 1;
  for (index = 0; index < 7; index++) {
    if (plate[index] == "?") {
      if (modern) {
        if (index == 2 || index == 3) {
          combinations = combinations * 10;
        } else {
          combinations = combinations * 26;
        }
      } else {
        combinations = combinations * 36;
      }

    }
  }

  return combinations;
}

function cleanPlate(plate) {
  return plate.replace(/\s/g, '').toUpperCase();
}

function validatePlateOther(plate) {
  if (plate.length < 2) {
    return {
      valid: false,
      error: "Invalid length. The number plate should be two or more characters long."
    };
  }

  for (index = 0;index < plate.length;index++) {
    if (!isLetterOrQuestion(plate[index]) && !(isNumberOrQuestion(plate[index]))) {
      return {
        valid: false,
        error: "The character at position " + index + " must be a number, letter or ?"
      };
    }
  }

  return {
    valid: true
  };
}

function validatePlateUKModern(plate) {
  if (plate.length != 7) {
    return {
      valid: false,
      error: "Invalid length. The number plate should be 7 characters long."
    };
  }

  if (!isLetterOrQuestion(plate[0])) {
    return {
      valid: false,
      error: "The 1st character must be a letter or a ?"
    };
  }

  if (!isLetterOrQuestion(plate[1])) {
    return {
      valid: false,
      error: "The 2nd character must be a letter or a ?"
    };
  }

  if (!isNumberOrQuestion(plate[2])) {
    return {
      valid: false,
      error: "The 3rd character must be a number or a ?"
    };
  }

  if (!isNumberOrQuestion(plate[3])) {
    return {
      valid: false,
      error: "The 4th character must be a number or a ?"
    };
  }

  if (!isLetterOrQuestion(plate[4])) {
    return {
      valid: false,
      error: "The 5th character must be a letter or a ?"
    };
  }

  if (!isLetterOrQuestion(plate[5])) {
    return {
      valid: false,
      error: "The 6th character must be a letter or a ?"
    };
  }

  if (!isLetterOrQuestion(plate[6])) {
    return {
      valid: false,
      error: "The 7th character must be a letter or a ?"
    };
  }

  return {
    valid: true
  };
}

function validatePlateNIModern(plate) {
  if (plate.length != 7) {
    return {
      valid: false,
      error: "Invalid length. The number plate should be 7 characters long."
    };
  }

  if (!isLetterOrQuestion(plate[0])) {
    return {
      valid: false,
      error: "The 1st character must be a letter or a ?"
    };
  }

  if (!isLetterOrQuestion(plate[1])) {
    return {
      valid: false,
      error: "The 2nd character must be a letter or a ?"
    };
  }

  if (!isLetterOrQuestion(plate[2])) {
    return {
      valid: false,
      error: "The 3rd character must be a letter or a ?"
    };
  }

  if (!isNumberOrQuestion(plate[3])) {
    return {
      valid: false,
      error: "The 4th character must be a number or a ?"
    };
  }

  if (!isNumberOrQuestion(plate[4])) {
    return {
      valid: false,
      error: "The 5th character must be a number or a ?"
    };
  }

  if (!isNumberOrQuestion(plate[5])) {
    return {
      valid: false,
      error: "The 6th character must be a number or a ?"
    };
  }

  if (!isNumberOrQuestion(plate[6])) {
    return {
      valid: false,
      error: "The 7th character must be a number or a ?"
    };
  }

  return {
    valid: true
  };
}

function isLetterOrQuestion(testchar) {
  let n = testchar.charCodeAt(0);
  return (n >= 65 && n < 91) || (n >= 97 && n < 123) || (n == 63);
}

function isNumberOrQuestion(testchar) {
  let n = testchar.charCodeAt(0);
  return (n >= 48 && n <= 57) || (n == 63);
}

module.exports = {
  plateSplit,
  validatePlateUKModern,
  validatePlateNIModern,
  validatePlateOther,
  cleanPlate,
  countCombinations
}
