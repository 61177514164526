import React, { useState, useRef, useEffect } from "react";
import Select from 'react-select';

const colours = [
  { value: 'BEIGE', label: "Beige" },
  { value: 'BLACK', label: "Black" },
  { value: 'BLUE', label: "Blue" },
  { value: 'BRONZE', label: "Bronze" },
  { value: 'BROWN', label: "Brown" },
  { value: 'BUFF', label: "Buff" },
  { value: 'CREAM', label: "Cream" },
  { value: 'GOLD', label: "Gold" },
  { value: 'GREEN', label: "Green" },
  { value: 'GREY', label: "Grey" },
  { value: 'IVORY', label: "Ivory" },
  { value: 'MAROON', label: "Maroon" },
  { value: 'ORANGE', label: "Orange" },
  { value: 'PINK', label: "Pink" },
  { value: 'PURPLE', label: "Purple" },
  { value: 'RED', label: "Red" },
  { value: 'SILVER', label: "Silver" },
  { value: 'TURQUOISE', label: "Turquoise" },
  { value: 'WHITE', label: "White" },
  { value: 'YELLOW', label: "Yellow" },
];

export default function ColourSelect(props) {

  return (
  <Select
    isMulti
    name="colors"
    placeholder="Colours (all)..."
    options={colours}
    isDisabled={props.isDisabled}
    onChange={props.onChange}
    className="basic-multi-select"
    classNamePrefix="select"
  />
);
}
