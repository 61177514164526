import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getCookieConsentValue } from "react-cookie-consent";

function sendPageView(pathname) {
  gtag("event", "page_view", {
    page_title: document.title,
    page_location: window.location, // Full URL and path e.g. https://example.com/info
    page_path: pathname, // e.g. /info
    send_to: process.env.REACT_APP_GA_PROPERTY,
  });
}

//function updateConfig(pathname) {
//  gtag('config', process.env.REACT_APP_GA_PROPERTY, {'page_path': pathname});
//}

function usePageTracking() {
  let location = useLocation();
  React.useEffect(() => {
    sendPageView(location.pathname);
  }, [location]);
}

function updateTrackingFromCookie() {
  const cookieEnabled = (getCookieConsentValue() === "true");
  window[process.env.REACT_APP_GA_PROPERTY_DISABLE] = !cookieEnabled;
}

export {sendPageView, usePageTracking, updateTrackingFromCookie}
