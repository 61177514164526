import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import "./App.css";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { usePageTracking, sendPageView, updateConfig } from "./PageTracking.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  vert: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
}));

function DeepConfirmation(props) {
  const location = useLocation();
  const state = location.state;

  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Paper elevation={3}>
        <Box m={2} py={2}>
          <Typography variant="h3">
          Order confirmed
          </Typography>
          <Typography variant="body1">
            <br />
            Thanks for your order {state.name}!<br />
            <br />
            Your report will be emailed to you at {state.email} within 24 hours.
            <br />
            <br />
            Your order number is {state.orderID}
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}

export default DeepConfirmation;
