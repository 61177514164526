import React, { useState, useRef, useEffect } from "react";
import Select from 'react-select';

const makes = [
  { value: 'ALFA ROMEO', label: 'Alfa Romeo' },
  { value: 'AUDI', label: 'Audi' },
  { value: 'BMW', label: 'Bmw' },
  { value: 'CHEVROLET', label: 'Chevrolet' },
  { value: 'CITROEN', label: 'Citroen' },
  { value: 'DACIA', label: 'Dacia' },
  { value: 'FIAT', label: 'Fiat' },
  { value: 'FORD', label: 'Ford' },
  { value: 'HONDA', label: 'Honda' },
  { value: 'HYUNDAI', label: 'Hyundai' },
  { value: 'JAGUAR', label: 'Jaguar' },
  { value: 'JEEP', label: 'Jeep' },
  { value: 'KIA', label: 'Kia' },
  { value: 'LAND ROVER', label: 'Land Rover' },
  { value: 'LEXUS', label: 'Lexus' },
  { value: 'MAZDA', label: 'Mazda' },
  { value: 'MERCEDES', label: 'Mercedes' },
  { value: 'MG', label: 'Mg' },
  { value: 'MINI', label: 'Mini' },
  { value: 'MITSUBISHI', label: 'Mitsubishi' },
  { value: 'NISSAN', label: 'Nissan' },
  { value: 'PEUGEOT', label: 'Peugeot' },
  { value: 'PORSCHE', label: 'Porsche' },
  { value: 'RENAULT', label: 'Renault' },
  { value: 'SAAB', label: 'Saab' },
  { value: 'SEAT', label: 'Seat' },
  { value: 'SKODA', label: 'Skoda' },
  { value: 'SMART', label: 'Smart' },
  { value: 'SUBARU', label: 'Subaru' },
  { value: 'SUZUKI', label: 'Suzuki' },
  { value: 'TESLA', label: 'Tesla' },
  { value: 'TOYOTA', label: 'Toyota' },
  { value: 'VAUXHALL', label: 'Vauxhall' },
  { value: 'VOLKSWAGEN', label: 'Volkswagen' },
  { value: 'VOLVO', label: 'Volvo' },
];

export default function MakeSelect(props) {

  return (
  <Select
    isMulti
    name="makes"
    placeholder="Makes (all)..."
    options={makes}
    isDisabled={props.isDisabled}    
    onChange={props.onChange}
    className="basic-multi-select"
    classNamePrefix="select"
  />
);
}
