import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./App.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  vert: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <StickyFooter>
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Information</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.vert}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>How it works</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                This website queries the DVLA vehicle enquiry service for each
                possible number plate combination from the pattern you submit.
                If you don't know two of the number plate letters then 676
                different possible plates have to be checked. This can take some
                time, especially as there is a rate limit on how quickly we can
                call the DVLA service.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                I know the make / colour of the vehicle. Can I search based on that?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The DVLA enquiry service doesn't support those types of search.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Why do you request cookie consent?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                This is used to provide Google adverts and Google analytics to
                help ensure the site is working properly.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                What are the terms and conditions?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                No liability is accepted for the availablity or accuracy of the
                service.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Feedback</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Any feedback or suggestions? Contact us{" "}
                <a href="mailto:info@alexanotify.com" target="_top">
                  here
                </a>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </div>
    </StickyFooter>
  );
}
