import React, { useState, useRef, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { GlobalStyle } from "./PlateFont.js";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import ColourSelect from "./ColourSelect.js";
import MakeSelect from "./MakeSelect.js";

// TODO: Works but not responsive
const isMobile = window.innerWidth < 480;

const plate_theme = createMuiTheme({
  typography: {
    fontFamily: "Arvo",
    fontSize: isMobile ? 48 : 72,
  },
});


export default function PlateEntry(props) {
  const [plate, setPlate] = useState("");
  const [type, setType] = React.useState(props.initialType);
  const [colourFilter, setColourFilter] = useState([]);
  const [makeFilter, setMakeFilter] = useState([]);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const inputEl = useRef(null);

  const handleAdvancedClick = (event) => {
    event.preventDefault();
    setShowAdvanced(true);
    gtag("event", "interaction", {
      event_category: "show_advanced"
    });
  };

  const handleColourChange = (event) => {
    var colours = [];
    for (const col of event) {
      colours.push(col.value);
    }
    setColourFilter(colours);
    props.onColorFilterChange(colours);
  };

  const handleMakeChange = (event) => {
    var makes = [];
    for (const make of event) {
      makes.push(make.value);
    }
    setMakeFilter(makes);
    props.onMakeFilterChange(makes);
  };

  useEffect(() => {
    if (inputEl.current) inputEl.current.focus();
  }, []);

  function handlePlateChange(event) {
    setPlate(event.target.value);
    props.onPlateChange(event.target.value);
  }

  function handleTypeChange(event) {
    setType(event.target.value);
    props.onTypeChange(event.target.value);
    setPlate(cleanInput(plate, event.target.value));
  }

  function handleSubmit(event) {
    event.preventDefault();
    props.callback(plate);
    return false;
  }

  function cleanInput(value, optionalType) {
    var value = value.replace(/\s/g, "").toUpperCase();
    var plate_type = optionalType ? optionalType : type;
    var new_value = "";

    for (var index = 0; index < value.length; index++) {
      var n = value.charCodeAt(index);
      if ((n >= 65 && n < 91) || (n >= 48 && n <= 57) || n == 63) {
        new_value = new_value + String.fromCharCode(n);
      }
    }

    if (plate_type == "gb" && new_value.length > 4) {
      new_value = new_value.slice(0, 4) + " " + new_value.slice(4);
    }

    if (plate_type == "ni" && new_value.length > 3) {
      new_value = new_value.slice(0, 3) + " " + new_value.slice(3);
    }

    return new_value;
  }

  return (
    <Container maxWidth="xs">
      <ThemeProvider theme={plate_theme}>
        <GlobalStyle />
        <TextField
          disabled={props.disabled}
          onInput={(e) => {
            e.target.value = cleanInput(e.target.value);
          }}
          value={plate}
          inputProps={{
            ref: inputEl,
            maxLength: 8,
            spellCheck: false,
            style: {
              textAlign: "center",
              textTransform: "uppercase",
              background: "yellow",
              fontFamily: "Major Mono Display",
              fontWeight: 800,
            },
          }}
          id="filled-basic"
          variant="filled"
          onChange={handlePlateChange}
        />
      </ThemeProvider>
      <FormControl
        component="fieldset"
        fullWidth={true}
        disabled={props.disabled}
      >
        <Paper elevation={3} style={{ marginTop: 4, marginBottom: 4 }}>
          <Box m={2} className="Caption">
            <Typography variant="caption" display="block" gutterBottom>
              Number plate type
            </Typography>
            <RadioGroup
              name="plate_type"
              value={type}
              onChange={handleTypeChange}
              row
            >
              <FormControlLabel
                value="gb"
                control={<Radio />}
                label={<Typography variant="body2">GB</Typography>}
              />
              <FormControlLabel
                value="ni"
                control={<Radio />}
                label={<Typography variant="body2">N. Ireland</Typography>}
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label={<Typography variant="body2">Other</Typography>}
              />
            </RadioGroup>
            {props.advancedEnabled && <Link
              onClick={handleAdvancedClick}
              hidden={showAdvanced}
              variant="body2"
              className="Caption"
            >
              {"Show advanced options"}
            </Link>}
          </Box>
        </Paper>
        <Paper
          elevation={3}
          style={{ marginTop: 4, marginBottom: 4 }}
          hidden={!showAdvanced}
        >
          <Box m={2} className="Caption">
            <Typography variant="caption" display="block" gutterBottom>
              Optional result filters
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ColourSelect
                  onChange={handleColourChange}
                  isDisabled={props.disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <MakeSelect
                  onChange={handleMakeChange}
                  isDisabled={props.disabled}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </FormControl>
    </Container>
  );
}
