import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from "@material-ui/core/Paper";

//import "./App.css";
//const R = require("ramda");



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function PlateTable(props) {

  const [orderBy, setOrderBy] = useState("registrationNumber");
  const [order, setOrder] = React.useState('asc');

  const createSortHandler = (property) => (event) => {
   onRequestSort(event, property);
 };

 const onRequestSort = (event, property) => {
   const isAsc = orderBy === property && order === 'asc';
   setOrder(isAsc ? 'desc' : 'asc');
   setOrderBy(property);
 };


 function descendingComparator(a, b, orderBy) {
   if (b[orderBy] < a[orderBy]) {
     return -1;
   }
   if (b[orderBy] > a[orderBy]) {
     return 1;
   }
   return 0;
 }

 function getComparator(order, orderBy) {
   return order === 'desc'
     ? (a, b) => descendingComparator(a, b, orderBy)
     : (a, b) => -descendingComparator(a, b, orderBy);
 }

 function stableSort(array, comparator) {
   const stabilizedThis = array.map((el, index) => [el, index]);
   stabilizedThis.sort((a, b) => {
     const order = comparator(a[0], b[0]);
     if (order !== 0) return order;
     return a[1] - b[1];
   });
   return stabilizedThis.map((el) => el[0]);
 }

  const classes = useStyles();
  var rows = null;
  var count = null;
  if (props.plate_rows) {
    count = props.plate_rows.length;
    rows = props.plate_rows;
  }

  const headCells = [
    { id: 'registrationNumber', numeric: false, disablePadding: true, label: 'Registration' },
    { id: 'colour', numeric: true, disablePadding: false, label: 'Colour' },
    { id: 'make', numeric: true, disablePadding: false, label: 'Make' },
    { id: 'taxStatus', numeric: true, disablePadding: false, label: 'Tax status' },
    { id: 'motStatus', numeric: true, disablePadding: false, label: 'MOT status' },
  ];

  return (
    <div>
      {count > 0 && (
        <div>
          <p>
            Matches found: {rows.length}
          </p>

          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">

              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>





              <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .map((row, index) => {
                  return (
                  <TableRow key={row.registrationNumber}>
                    <TableCell component="th" scope="row">
                      {row.registrationNumber}
                    </TableCell>
                    <TableCell align="right">{row.colour}</TableCell>
                    <TableCell align="right">{row.make}</TableCell>
                    <TableCell align="right">{row.taxStatus}</TableCell>
                    <TableCell align="right">{row.motStatus}</TableCell>
                  </TableRow>
                );
                }
              )}
              </TableBody>
            </Table>
          </TableContainer>
          </Paper>
        </div>
      )}
    </div>
  );
}

export default PlateTable;
