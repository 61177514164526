import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ReactDOM from "react-dom";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import "./App.css";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import PlateEntry from "./PlateEntry.js";
import DeepInstructions from "./DeepInstructions.js";
import { usePageTracking, sendPageView, updateConfig } from "./PageTracking.js";
import plate_utils from "./plate.js";

const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });
//TODO try defer script again

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  vert: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
}));

function StatusLine(props) {
  return <Typography variant="h6"> {props.text}</Typography>;
}

function PPB() {
  const history = useHistory();
  const [plate, setPlate] = useState("");
  const [type, setType] = useState("gb");
  const [colourFilter, setColourFilter] = useState([]);
  const [makeFilter, setMakeFilter] = useState([]);
  const [messageText, setMessageText] = useState(
    "£0.99 deep offline search. Enter a number plate pattern with three ? placeholders."
  );

  const createOrder = (data, actions) => {
    //TODO not sure how we would cancel if the input isn't valid
    // Call your server to set up the transaction
    //return fetch("/demo/checkout/api/paypal/order/create/", {

    var plate_data = {
      plate: plate,
      type: type
    };

    return fetch(process.env.REACT_APP_PP_URL + "/create/", {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(plate_data),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (orderData) {
        return orderData.id;
      });
  };

  const onCancel = (data, actions) => {
    console.log("Cancelled");
  };

  const onClick = (data, actions) => {
    var submit_plate = plate.replace(/\s/g, "").toUpperCase();

    const placeholderCount = submit_plate.split("?").length;

    if (placeholderCount != 4) {
      setMessageText("A paid deep search should only be used with 3 ? placeholders");
      return false;
    }

    var valid = null;

    switch (type) {
      case "gb":
        valid = plate_utils.validatePlateUKModern(submit_plate);
        break;

      case "ni":
        valid = plate_utils.validatePlateNIModern(submit_plate);
        break;

      default:
        valid = plate_utils.validatePlateOther(submit_plate);
        break;
    }

    if (!valid.valid) {
      setMessageText(valid.error + " Did you select the right plate type?");
      return false;
    }

    return true;
  };


  const onApprove = (data, actions) => {
    // Call your server to finalize the transaction
    return fetch(process.env.REACT_APP_PP_URL + "/order/" + data.orderID + "/capture/", {
      method: "post",
    })
      .then(function (res) {
        return res.status == 200 ? res.json() : null;
      })
      .then(function (orderData) {
        // Added this and the 200 check above so that the server can
        // determine whether to complete the capture.
        if (!orderData) {
          setMessageText("Your order could not be completed");
          return;
        }
        // Three cases to handle:
        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        //   (2) Other non-recoverable errors -> Show a failure message
        //   (3) Successful transaction -> Show confirmation or thank you

        // This example reads a v2/checkout/orders capture response, propagated from the server
        // You could use a different API or structure for your 'orderData'
        var errorDetail =
          Array.isArray(orderData.details) && orderData.details[0];

        if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
          return actions.restart(); // Recoverable state, per:
          // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
        }

        if (errorDetail) {
          var msg = "Sorry, your transaction could not be processed.";
          if (errorDetail.description) msg += "\n\n" + errorDetail.description;
          if (orderData.debug_id) msg += " (" + orderData.debug_id + ")";
          setMessageText(msg);
          return;
        }

        //console.log(
        //  "Capture result",
        //  orderData,
        //  JSON.stringify(orderData, null, 2)
        //);
        var transaction = orderData.purchase_units[0].payments.captures[0];
        var name = orderData.purchase_units[0].shipping.name.full_name;
        var email = orderData.payer.email_address;

        if (transaction.status == "COMPLETED") {

          history.push({
            pathname: '/confirmation',
            state: {
              name: name,
              email: email,
              orderID: transaction.invoice_id
            },
          });

          return;
        } else {
          var msg = "Order did not complete: " + transaction.status;
          setMessageText(msg);
          return;
        }

      });
  };

  function plateSubmit(event, noWarn = false) {}

  return (
    <div className="App">
      <Container maxWidth="md">
        <Box
          my={4}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box>
            <form onSubmit={plateSubmit}>
              <Box p={1} my={4}>
                <PlateEntry
                  disabled={false}
                  onPlateChange={setPlate}
                  onTypeChange={setType}
                  onColorFilterChange={setColourFilter}
                  onMakeFilterChange={setMakeFilter}
                  initialType={type}
                />
              </Box>
            </form>
            <Container maxWidth="sm">
              <PayPalButton
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
                onCancel={(data, actions) => onCancel(data, actions)}
                onClick={(data, actions) => onClick(data, actions)}
              />
            </Container>
            <StatusLine text={messageText} />
            <DeepInstructions />
          </Box>
        </Box>
      </Container>
    </div>
  );
}

function Deep(props) {
  const classes = useStyles();

  return (
    <div className="Instructions">
      <PPB />
    </div>
  );
}

export default Deep;
