import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import "./App.css";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

function Instructions(props) {
  //return <Typography variant="body2" Hello />;
  return (
    <div className="Instructions">
      <Container maxWidth="md">
        <Paper elevation={3}>
          <Box m={2} py={2}>
            <Typography variant="body1">
              Need to find a UK registration plate but don't know all of the characters?<br/>
              Perhaps you've been involved in a hit-and-run (failed to stop) incident?
              This site can help.
            </Typography>
            <br />
            <Typography variant="body1">
              You can enter up to two ? in place of any characters you don't know.<br/>
              The <a href="/deep">paid offline</a> service can be used if you don't know 3 of the characters.<br/>
              The modern GB number plate format is shown in the diagram below. <br/>
              The modern Northern Ireland plate format is 3 letters and 4 digits. <br/>
              You can select 'other' for older plates and private plates.
            </Typography>
            <br />
            <Box width="25%" height="25%">
              <img
                src="https://www.thecarexpert.co.uk/wp-content/uploads/2019/03/GB-number-plate-example.jpg"
                alt="UK number plate"
                width="100%"
              />
            </Box>
            <br />
            <Typography variant="body1">Example: BD51 ?MR</Typography>
            <br />
            <Typography variant="body1">
              Your search may need to join a queue as the DVLA limits our rate
              of requests. See more information <a href="/info"> here </a>
            </Typography>
            <Typography variant="body1">
              You can read more about number plate formats{" "}
              <a href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/359317/INF104_160914.pdf">
                here
              </a>
              &nbsp;and&nbsp;
              <a href="https://www.thecarexpert.co.uk/how-does-the-uk-number-plate-system-work">
                here
              </a>
            </Typography>
            <Typography variant="body1">
              Copyright © 2021
              <a href="https://www.plummet.io">
                Plummet Ltd.
              </a>
              &nbsp;All rights reserved.
            </Typography>
            <Typography variant="body1">
              Contains public sector information licensed under the Open
              Government Licence v3.0.
            </Typography>
          </Box>
        </Paper>
      </Container>
    </div>
  );
}

export default Instructions;
