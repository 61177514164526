import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import {usePageTracking, sendPageView, updateConfig, updateTrackingFromCookie} from "./PageTracking.js";
import Info from "./Info.js";
import Main from "./Main.js";
import Deep from "./Deep.js";
import DeepConfirmation from "./DeepConfirmation.js";
import MainMenu from "./MainMenu.js";

export default function App() {
  updateTrackingFromCookie();
  usePageTracking();

  return (
      <>
      <MainMenu className="App" />
      <CookieConsent
        enableDeclineButton
        buttonText="Accept"
        declineButtonText="Decline"
        flipButtons="true"
        onAccept={() => {
           updateTrackingFromCookie();
           sendPageView();
         }}
         onDecline={() => {
           updateTrackingFromCookie();
          }}
      >
        By continuing to visit this site, you accept the use of cookies by
        Google Analytics for statistical purposes.
      </CookieConsent>
      <Switch>
      <Route path="/info" >
        <Info />
      </Route>
      <Route path="/deep" >
        <Deep />
      </Route>
      <Route path="/confirmation" >
        <DeepConfirmation />
      </Route>
      <Route exact path="/" >
        <Main />
      </Route>
      </Switch>
      </>
  );
}

function progressPercentage(current, total) {
  return (current / total) * 100;
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
