import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import "./App.css";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { usePageTracking, sendPageView, updateConfig } from "./PageTracking.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  vert: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
}));

function Info(props) {
  const classes = useStyles();
  //updateConfig('/info');

  return (
    <div className="Instructions">
      <Container maxWidth="md">
        <Box my={4}>
          <Paper elevation={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  How it works
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  This website queries the DVLA vehicle enquiry service for each
                  possible number plate combination from the pattern you submit.
                  If you don't know two of the number plate letters then 676
                  different possible plates have to be checked. This can take
                  some time, especially as there is a rate limit on how quickly
                  we can call the DVLA service.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  I know the make / colour of the vehicle. Why doesn't that make
                  the search quicker?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  The DVLA enquiry service does not support make / colour
                  filters. So, we have to check all possible plates and then
                  filter the results.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Why don't see my make / colour of vehicle listed in the filter
                  options?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  The DVLA match vehicle colours to a very limited list of
                  'basic' colours. There are several hundred registered vehicle
                  makes so we only included the most popular ones in the filter
                  options.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Why do you request cookie consent?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  This is used to enable Google analytics to help ensure the
                  site is working properly.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  What are the terms and conditions?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  No liability is accepted for the availablity or accuracy of
                  the service.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Copyright notice and feedback
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" color="textSecondary" align="left">
                  Plummet Ltd. trading as plummet.io.
                  <br />
                  Copyright © 2021 Plummet Ltd.
                  <br />
                  All rights reserved.
                  <br />
                  <span>
                    Contact us:&nbsp;
                    <a href="mailto:info@plummet.io" target="_top">
                      info@plummet.io
                    </a>
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Box>
      </Container>
    </div>
  );
}

export default Info;
