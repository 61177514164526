import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as ANMIcon } from "./anm.svg";
import { withRouter } from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';
import "./App.css";

//import { UserContext, UserTypes } from "./Context.js";

require("dotenv").config();

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    textAlign: "center"

  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: "1rem"
  },
});

class MainMenu extends Component {

  onMenuIconClick = (path) => {
    this.props.history.push(path);
    if (path=="/") location.reload();
  };

  render() {
    const { classes } = this.props;
    const { type, nodeRef } = this.context;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <SvgIcon
              component={ANMIcon}
              className={classes.menuButton}
              onClick={this.onMenuIconClick.bind(this, "/")}
            />
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={this.onMenuIconClick.bind(this, "/info")}
            >
            <InfoIcon />
            </IconButton>

            <Typography variant="h6" className={classes.title}>Partial UK number plate search</Typography>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(MainMenu));
//export default withStyles(styles)(MainMenu);
