import { createGlobalStyle } from 'styled-components';
import UKNumberPlate from './fonts/UKNumberPlate.ttf';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Major Mono Display';
    src: url(${UKNumberPlate}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: auto;
  }
`;
