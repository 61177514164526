import React, { useState } from "react";
import { Banner } from "material-ui-banner";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import "./App.css";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function NewsBanner(props) {
  const classes = useStyles();
  const [showBanner, setShowBanner] = useState(true);

  function handleOnClose(event) {
    setShowBanner(false);
  }

  function handleOnClick(event) {
    setShowBanner(false);
  }

  return (
    <div>
      {showBanner && (
        <Banner
          onClose={handleOnClose}
          label="Developers: API keys are now available. Login then open the developer page"
        />
      )}
    </div>
  );
}

export default NewsBanner;
