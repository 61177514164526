import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import ProTip from "./ProTip";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PlateTable from "./plateTable.js";
import Paper from "@material-ui/core/Paper";

import Header from "./Header.js";
import Button from "@material-ui/core/Button";
import NewsBanner from "./NewsBanner.js";
import Instructions from "./Instructions.js";
import Footer from "./Footer.js";
import { v4 as uuidv4 } from "uuid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { GlobalStyle } from "./PlateFont.js";
import plate_utils from "./plate.js";
import ColourSelect from "./ColourSelect.js";
import MakeSelect from "./MakeSelect.js";
import PlateEntry from "./PlateEntry.js";
import AlertDialog from "./AlertDialog.js";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//import { Router as Router, Switch, Route } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
//import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Select from "react-select";

import { usePageTracking, sendPageView, updateConfig } from "./PageTracking.js";
require("dotenv").config();

import "./App.css";

const clientId = uuidv4();
var socket = null;
var debug = false;

const state = {
  STATE_INITIAL: "state_initial",
  STATE_SUBMITTED: "state_submitted",
  STATE_QUEUING: "state_queuing",
  STATE_WORKING: "state_working",
  STATE_COMPLETE: "state_complete",
};

const message = {
  MSG_CS_PLATE: "msg_cs_plate",
  MSG_SC_ERROR: "msg_sc_error",
  MSG_SC_QUEUE: "msg_sc_queue",
  MSG_SC_PROGRESS: "msg_sc_progress",
  MSG_SC_MATCH: "msg_sc_match",
  MSG_SC_COMPLETE: "msg_sc_complete",
};

// TODO: Works but not responsive
const isMobile = window.innerWidth < 480;

function StatusLine(props) {
  return <Typography variant="h6"> {props.text}</Typography>;
}

function submitSearch(clientId, plate, type, rcToken, callback) {
  plate = plate.replace(/\s/g, "").toUpperCase();
  socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);

  // Connection opened
  socket.addEventListener("open", function (event) {
    socket.send(
      JSON.stringify({
        message: message.MSG_CS_PLATE,
        client: clientId,
        rc_token: rcToken,
        data: { plate: plate, type: type },
      })
    );
  });

  // Listen for error
  socket.addEventListener("error", function (event) {
    console.log("Error from server ", event.data);
    callback(null);
  });

  // Listen for close
  socket.addEventListener("close", function (event) {
    //console.log("Close from server ", event.data);
  });

  // Listen for messages
  socket.addEventListener("message", function (event) {
    //console.log("Message from server ", event);
    const data = JSON.parse(event.data);
    callback(data);
  });
}

export default function Main() {
  const [current_state, setState] = useState(state.STATE_INITIAL);
  const [messageText, setMessageText] = useState(
    "Enter a number plate pattern to start. Use ? for characters you don't know"
  );
  const [plates, setPlates] = useState([]);
  const [current_progress, setCurrentProgress] = useState(0);
  const [total_progress, setTotalProgress] = useState(0);
  const [plate, setPlate] = useState("");
  const [type, setType] = useState("gb");
  const [colourFilter, setColourFilter] = useState([]);
  const [makeFilter, setMakeFilter] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deepDialogOpen, setDeepDialogOpen] = useState(false);
  const history = useHistory();

  var platesStore = [];
  var skippedByFilter = 0;

  //updateConfig ('/');

  function setToInitial() {
    socket.close(); // Safe to do even if already closed
    setCurrentProgress(0);
    setTotalProgress(100);
    skippedByFilter = 0;
    platesStore = [];
    setPlates(platesStore);
    setMessageText(
      "Enter a number plate pattern to start. Use ? for characters you don't know"
    );
    setState(state.STATE_INITIAL);
  }

  function dialogClosed(searchAnyway) {
    setDialogOpen(false);
    if (searchAnyway) {
      plateSubmit(null, true);
    }
  }

  function deepDialogClosed(gotoDeep) {

    setDeepDialogOpen(false);
    if (gotoDeep) {
      history.push("/deep");
    }
  }

  function plateSubmit(event, noWarn = false) {
    var submit_plate = plate.replace(/\s/g, "").toUpperCase();
    if (submit_plate == "DEBUGON") {
      gtag("event", "interaction", {
        event_category: "debug_enabled",
      });
      setMessageText("Debug enabled");
      debug = true;
      return;
    }

    const placeholderCount = submit_plate.split("?").length;
    //const maxPlaceholders = debug ? 3:2;
    const maxPlaceholders = 2;

    if (placeholderCount > maxPlaceholders + 1) {
      //const maxNumStr = debug ? "three" : "two";
      //const maxNumStr = "two";
      //setMessageText(
      //  "A maximum of " + maxNumStr + " ? characters can be used."
      //);
      //return;
      setDeepDialogOpen(true);
      return;
    }

    var valid = null;

    switch (type) {
      case "gb":
        valid = plate_utils.validatePlateUKModern(submit_plate);
        break;

      case "ni":
        valid = plate_utils.validatePlateNIModern(submit_plate);
        break;

      default:
        valid = plate_utils.validatePlateOther(submit_plate);
        break;
    }

    if (!valid.valid) {
      setMessageText(valid.error + " Did you select the right plate type?");
      return;
    }

    if (placeholderCount <= 1 && !noWarn) {
      setDialogOpen(true);
      return;
    }

    if (event) event.preventDefault();
    setCurrentProgress(0);
    setTotalProgress(100);
    skippedByFilter = 0;
    platesStore = [];
    setPlates(platesStore);
    setState(state.STATE_SUBMITTED);
    setMessageText("Submitting request");
    gtag("event", "search", {
      event_category: "type",
      event_label: type,
    });

    grecaptcha.enterprise.ready(function () {
      grecaptcha.enterprise
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: "SUBMIT" })
        .then(function (token) {
          setTimeout(
            submitSearch,
            1000,
            clientId,
            submit_plate,
            type,
            token,
            onPlateEvent
          );
        });
    });
  }

  function onPlateEvent(event) {
    //console.log("onPlateEvent: " + JSON.stringify(event));
    if (!event) {
      setToInitial();
      setMessageText("Server unavailable. Please try again later.");
      return;
    }

    if (event.message == message.MSG_SC_QUEUE) {
      setState(state.STATE_QUEUING);
      setMessageText("Queue position: " + event.data);
    }
    if (event.message == message.MSG_SC_PROGRESS) {
      if (current_state != state.STATE_WORKING) {
        setMessageText(
          "Searching " + event.data.total + " possible combinations"
        );
        setState(state.STATE_WORKING);
      }
      setCurrentProgress(event.data.current);
      setTotalProgress(event.data.total);
    }
    if (event.message == message.MSG_SC_ERROR) {
      setToInitial();
      setMessageText(event.data);
    }
    if (event.message == message.MSG_SC_MATCH) {
      var filterMatch = true;

      if (
        colourFilter.length > 0 &&
        !colourFilter.includes(event.data.colour)
      ) {
        filterMatch = false;
      }

      if (makeFilter.length > 0 && !makeFilter.includes(event.data.make)) {
        filterMatch = false;
      }

      if (filterMatch) {
        platesStore = platesStore.concat(event.data);
        setPlates(platesStore);
      } else {
        skippedByFilter = skippedByFilter + 1;
      }
    }
    if (event.message == message.MSG_SC_COMPLETE) {
      if (skippedByFilter > 0) {
        setMessageText("Search complete");
      } else {
        setMessageText("Search complete");
      }

      setState(state.STATE_COMPLETE);
    }
  }

  function handleOnClick(event) {
    if (
      current_state == state.STATE_WORKING ||
      current_state == state.STATE_SUBMITTED ||
      current_state == state.STATE_QUEUING
    ) {
      setToInitial();
    } else {
      plateSubmit(event);
    }
  }
  return (
    <div className="App">
      <AlertDialog
        open={dialogOpen}
        handleClose={dialogClosed}
        cancelText="Edit"
        acceptText="Search anyway"
        title="No ? placeholders in search pattern"
        description="Your search pattern doesn't include any ? placeholders. If you want to search multiple plates then the pattern should be e.g. BD?? SMR or BD51 SM?"
      />
      <AlertDialog
        open={deepDialogOpen}
        handleClose={deepDialogClosed}
        cancelText="Cancel"
        acceptText="Go to deep search"
        title="Deep search?"
        description="The free search only supports two ? placeholders. Would you like to perform a paid deep search?"
      />
      <Container maxWidth="md">
        <Box
          my={4}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box>
            <form onSubmit={plateSubmit}>
              <Box p={1} my={4}>
                <PlateEntry
                  disabled={
                    current_state != state.STATE_INITIAL &&
                    current_state != state.STATE_COMPLETE
                  }
                  advancedEnabled={true}
                  onPlateChange={setPlate}
                  onTypeChange={setType}
                  onColorFilterChange={setColourFilter}
                  onMakeFilterChange={setMakeFilter}
                  initialType={type}
                />
              </Box>
              <Box p={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOnClick}
                  size="small"
                >
                  {(current_state == state.STATE_INITIAL ||
                    current_state == state.STATE_COMPLETE) && <b>Search</b>}
                  {(current_state == state.STATE_WORKING ||
                    current_state == state.STATE_QUEUING ||
                    current_state == state.STATE_SUBMITTED) && <b>Cancel</b>}
                </Button>
              </Box>
            </form>
          </Box>
          {current_state == state.STATE_WORKING && (
            <LinearProgressWithLabel
              value={progressPercentage(current_progress, total_progress)}
            />
          )}
          {current_state == state.STATE_QUEUING && (
            <Box
              my={4}
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          )}
          <StatusLine text={messageText} />
          {current_state == state.STATE_INITIAL && <Instructions />}
          <PlateTable
            disabled={current_state != state.STATE_SUBMITTED}
            plate_rows={plates}
          />
        </Box>
      </Container>
    </div>
  );
}

function progressPercentage(current, total) {
  return (current / total) * 100;
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
