import React from "react";
//import { UserContext, UserTypes } from "./Context.js";
import "./App.css";

require("dotenv").config();

function Header(props) {
  return (
        <header className="App-header">
            <p className="App-title">Partial UK number plate search</p>
        </header>
  );
}

export default Header;
