import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import "./App.css";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

function DeepInstructions(props) {
  //return <Typography variant="body2" Hello />;
  return (
    <div className="Instructions">
      <Container maxWidth="md">
        <Paper elevation={3}>
          <Box m={2} py={2}>
            <Typography variant="body1">
              The <a href="/">free</a> search can be used with up to two ? placeholders.<br/>
              If you need a deeper search with three ? placeholders then you can use this paid search service for £0.99<br/><br/>
              We'll do the search offline and email you the results within 24 hours.<br/>
              A paid offline search is needed for 3 placeholders as there can be over 46,000 plates to check.<br/><br/>
              Vehicle make and colour filters are not required for offline searches — all possible matches will be returned.
            </Typography>
            <br />
            <Typography variant="body1">
              Copyright © 2021
              <a href="https://www.plummet.io">
                Plummet Ltd.
              </a>
              &nbsp;All rights reserved.
            </Typography>
            <Typography variant="body1">
              Contains public sector information licensed under the Open
              Government Licence v3.0.
            </Typography>
          </Box>
        </Paper>
      </Container>
    </div>
  );
}

export default DeepInstructions;
